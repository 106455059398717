@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');


html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Poppins', sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #0d0f19;
  box-sizing: border-box;
}

.main-content {
  flex: 1;
}

/* Activate */
.activate {
  max-width: 1000px;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  color: aliceblue;
}

.activate-button {
  background-color: #2A38C7;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;

}

.activate-container {
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 50px;
  border-radius: 10px;
}

/* Header */
.header {
  background-color: #0d0f19;
  color: white;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
  margin: 0 auto;
  justify-content: flex-end;
}

.header-mobile{
  display: none;
}

a.header-logo {
  margin-right: 250px;
}


nav.header-navigation {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}

a.header-logo {
  margin-right: 250px;
}

.hamburger-icon{
  display: none;
}

.btn-primary {
  background-color: #2A38C7;
  color: #ffffff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  margin-right: 10px;
}

.header-logo {
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  color: white;
}

a.header-logo {
  margin-right: 250px;
}

.header-navigation a {
  color: white;
  margin: 0 15px;
  text-decoration: none;
}

.nav.header-navigation a:hover {
  text-decoration: underline;
}

.header-group {
  display: flex;
  align-items: center;
}

/* Home */
.home {
  max-width: 1000px;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  color: aliceblue;
}

.home-subtext {
  font-size: 16px;
  color: rgb(100 116 139);
  max-width: 800px;
}

.join-button {
  background-color: #2A38C7;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  margin-top: 20px;
  cursor: pointer;
}

.join-button a {
  text-decoration: none;
  color: white;
}

.join-button:hover {
  background-color: #0d1572;
  transform: scale(1.1);
  transition: transform 0.5s;
}

.login-button {
  background-color: #211f2d;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  margin-top: 20px;
  cursor: pointer;
}

.login-button a {
  text-decoration: none;
  color: white;
}

.login-button:hover {
  background-color: #1a1c24;
  transform: scale(1.1);
  transition: transform 0.5s;
}

.home-second-container {
  margin-top: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 100px;
}

.grid-section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 50px;
}

.grid-item:hover {
  transform: scale(1.1);
  transition: transform 0.5s;
}

.grid-item-text {
  color: rgb(100 116 139);
}

.grid-item img {
  width: 20%;
  height: 30%;
  border-radius: 10px;
}

.toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 10px 20px;
  border-radius: 20px;
  z-index: 1000;
  animation: slideIn 0.5s ease-out;
}

@keyframes slideIn {
  from {
    transform: translateX(-50%) translateY(100%);
  }

  to {
    transform: translateX(-50%) translateY(0);
  }
}

/* Modal */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: #0d0f19;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 500px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #0d0f19;
  color: #333;
  padding: 5px 10px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.2s, color 0.2s;
}

.purchase-button {
  background-color: #211f2d;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
}

.purchase-button:hover {
  background-color: #2A38C7;
  transform: scale(1.01);
  transition: transform 0.5s;
}

.balance-add-button {
  background-color: #211f2d;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 10px;
  width: 400px;
  margin: 0 auto;
  cursor: pointer;
}

.balance-add-button:hover {
  background-color: #2A38C7;
  transform: scale(1.01);
  transition: transform 0.5s;
}

.input-balance {
  padding: 10px;
  border-radius: 5px;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  align-items: center;
  font-size: 16px;
}

.input-balance-label {
  text-align: center;
  justify-content: center;
  margin-bottom: 10px;
  display: flex;
}

.input-balance input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}

/* Form Styles */
.modal form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modal select {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}


/* login */
.login {
  max-width: 1000px;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  color: aliceblue;
}

.login-container {
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 50px;
  border-radius: 10px;
}

.input {
  display: flex;
  align-items: center;
  margin: auto;
  width: 480px;
  height: 45px;
  background: #eaeaea;
  border-radius: 6px;
  margin-top: 20px;
}

.input img {
  margin: 0px 30px;
}

.input input {
  height: 50px;
  width: 400px;
  background: transparent;
  border: none;
  outline: none;
  color: #797979;
  font-size: 16px;
}

.button-group {
  display: flex;
  justify-content: right;
  align-items: center;
}

/* Apply */
.apply-container {
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  color: aliceblue;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Products */
.products {
  max-width: 1000px;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  color: aliceblue;
}

.products-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.product-grid,
.product-grid-2,
.product-grid-3 {
  display: grid;
  padding: 25px 100px;
  gap: 10px;
  margin-top: 50px;
  background-color: #211f2d;
  max-width: 100px;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
}

.product-grid:hover,
.product-grid-2:hover,
.product-grid-3:hover {
  transform: scale(1.015);
  transition: transform 0.5s;
}

.product-title {
  font-size: 1.2rem;
  margin: 0;
  color: white;
}

.product-version {
  margin: 0;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
}

.sweatvpn-download-btn,
.sweatplay-download-btn,
.sweatconsole-download-btn {
  background-color: #2A38C7;
  color: #ffffff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 10px;
  cursor: pointer;
}

/* Logged In Header */
.logged-in-header {
  background-color: #0d0f19;
  color: white;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1050px;
  margin: 0 auto;
}

.logged-in-user {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 50px;
  margin-left: 10px;
}

.btn-dashboard a {
  color: #fff;
  text-decoration: none;
}

.btn-dashboard {
  background-color: #2A38C7;
  color: #ffffff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  margin-right: 10px;
  text-decoration: none;
}

/* Dashboard */
.h5-group {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.display-count-selector {
  margin-bottom: 10px;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.pagination-controls button {
  margin: 0 5px;
  padding: 5px 20px;
  background-color: #2A38C7;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.pagination-controls button:disabled {
  background-color: #cccccc;
  cursor: default;
}

.key-info-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  color: aliceblue;
}

.panel-header {
  margin: 0 auto;
  width: 1050px;
}

.status-grids {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 50px;
  width: 1050px;
}

.status-grid {
  background-color: #6c63ba;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: start;
}

.status-grid .status-icon {
  width: 8%;
  height: auto;
  margin-right: 30px;
}

.status-grid .text-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.status-grid h1 {
  font-size: 1rem;
  margin: 0;
}

.status-grid p {
  font-size: 1.5rem;
  margin: 0;
}

.btn {
  background-color: #211f2d;
  color: #ffffff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  margin-right: 10px;
}

.btn-2 {
  background-color: #211f2d;
  color: #ffffff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  margin-right: 10px;
}

.btn-3 {
  background-color: #211f2d;
  color: #ffffff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  margin-right: 10px;
}

.btn-3 a {
  text-decoration: none;
  color: white;
}

.key-table {
  margin-top: 25px;
  width: 1050px;
}

.key-row {
  background-color: #211f2d;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.selected-key {
  background-color: rgb(21, 86, 179);
}

.key-info-header,
.key-details {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
}

.key-info-header,
.key-details {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
}

.key-info-header h5,
.key-details h5 {
  text-align: center;
  margin: 0;
  /* Adjust as needed */
}

.key-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 65%;
}

.key-row:hover,
.status-grid:hover,
.btn:hover,
.btn-2:hover,
.btn-3:hover {
  transform: scale(1.015);
  transition: transform 0.5s;
}

.key-row img.key-icon {
  width: 20px;
  height: auto;
  margin-right: 20px;
}

.key-row .key-text-content {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.key-row .key-text-content p {
  flex: 1;
  margin: 0;
  font-size: 1rem;
}


/* Discord */
.discord {
  background: #211f2d;
  color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  margin-top: 50px;
  width: 1020px;
  display: flex;
  justify-content: center;
}

.discord a {
  text-decoration: none;
  color: rgb(105, 105, 105);
}

.discord span {
  color: rgb(105, 105, 105);
}

.discord:hover {
  transform: scale(1.015);
  transition: transform 0.5s;
}

.discord-section {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.discord-icon {
  border-radius: 50%;
  margin-right: 20px;
  width: 50px;
  height: auto;
}

.discord-section h5 {
  margin: 0 10px;
  white-space: nowrap;
}

/* Footer */
.footer {
  background-color: #0d0f19;
  color: white;
  padding: 1rem;
  text-align: center;
}

a.header-logo {
  margin-right: 250px;
}

@media (max-width: 768px) {
  .logged-in-header {
    width: 90%;
    flex-direction: column;
    align-items: center;
    visibility: visible;
  }

 
  .status-grids {
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    align-items: center;
    width: 90%
  }

  .panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    color: aliceblue;
  }

  .panel-header {
    width: 90%;
    flex-direction: column;
    align-items: center;
  }

  h1.panel-header {
    display: flex;
  }

  .logged-in-header .header-group {
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  .discord-section {
    flex-direction: column;
    align-items: center;
  }

 
  .discord,
  .modal,
  .panel,
  .keys-row {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .logged-in-user{
    margin: 0 auto;
  }

  a.header-logo {
    margin-right: 0;
  }

  .home {
    margin-top: 50px;
    width: 90%;
    text-align: center;
  }

  .grid-section,
  .btn-group,
  .btn,
  .btn-2,
  .btn-3{
    flex-direction: column;
    align-items: center;
    grid-template-columns: repeat(1, 1fr);

  }

  .grid-section img {
    width: 10%;
    height: 10%;
  }

  .modal {
    max-width: 70%;
}

.hamburger-icon{
  display: block;
  cursor: pointer;
}

.header-navigation{
  display: none;
}

.btn-group {
  width: 90%;
  justify-content: center;
  align-items: center;
}

.sidenav{
margin: 0 auto;
justify-content: center;
align-items: center;
grid-template-columns: repeat(1, 1fr);
}


.sidenav a{
  justify-content: center;
  text-align: center;
}

.input {
  max-width: 400px;
}

.products-group{
  align-items: center;
  display: grid;
  justify-content: center;
  margin: 0 auto;
}

h1.products-header {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

nav.header-navigation {
  display: none;
}

button.btn-dashboard {
  justify-content: center;
  margin: 0 auto;
  display: flex;
}

button.btn-primary {
  justify-content: center;
  margin: 0 auto;
  display: flex;
  margin-top: 5px;
}

.header-mobile {
  display: flex;
  align-items: center;
  margin-top: 10px;
  color: white;
  font-size: x-large;
  text-decoration: none;
  font-weight: bold;
}

.key-table{
  width: 100%;
  margin-top: 0;
}

.key-row{
  align-items: center;
  background-color: #211f2d;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 5px;
  width: 97%;
}

}